main {
    min-height: 80vh;
  }

  body{

    margin: 0;
    background-color: rgb(7, 0, 0);
  }
  
  .rating span {
    margin: 0.1rem;
  }
  
  .rating svg {
    color: #f8e825;
  }
  
  .rating-text {
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 0.5rem;
  }
  
  .product-title {
    height: 2.5em; /* Set a fixed height */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis for long text */
    white-space: nowrap; /* Prevent wrapping */
  }
  
  table td,
  table th {
    text-align: center;
  }
  
  .review {
    margin-top: 30px;
  }
  
  .review h2 {
    font-size: 24px;
    background: #f4f4f4;
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .review button {
    margin-top: 10px;
  }
  
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 90px;
  padding: 20px;
  margin: 10px;
  border-radius: 0%;
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}

.card img{
  padding: 5px;
  margin: 30px;
  border-radius: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;

}

.card{
  background-color: grey; color: white;
}



